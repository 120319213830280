var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{class:_vm.vertical ? '' : 'mb-1',style:('background-color:rgba(' +
      (_vm.$store.state.appConfig.layout.skin === 'dark'
        ? '41,48,68'
        : '255,255,255') +
      ',' +
      _vm.backgroundOpacity || 1 + ')')},[(_vm.title)?_c('b-card-title',{staticClass:"font-title mb-1"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"TriangleIcon","size":"18"}}),_vm._v(" "+_vm._s(_vm.title)+" ")],1):_vm._e(),_c('div',{staticClass:"pt-1",staticStyle:{"height":"calc(100% - 5rem)"}},[_c('div',{staticClass:"d-flex",class:_vm.vertical ? 'h-50' : 'h-100'},[(_vm.vertical)?[_c('div',{staticClass:"h-100",staticStyle:{"width":"20%"}})]:_vm._e(),_c('div',{staticClass:"h-100 position-relative",style:('width: ' +
          (_vm.vertical ? '30%' : '20%') +
          ';top:' +
          (_vm.vertical ? '0' : '0') +
          ';left:' +
          (_vm.vertical ? '0' : '1.8rem')),attrs:{"id":'real_time_air_quality_chart_1_' + _vm.nanoid}}),_c('div',{staticClass:"h-100 position-relative",style:('width: ' +
          (_vm.vertical ? '30%' : '20%') +
          ';top:' +
          (_vm.vertical ? '0' : '-2rem') +
          ';left:' +
          (_vm.vertical ? '0' : '3.5rem')),attrs:{"id":'real_time_air_quality_chart_2_' + _vm.nanoid}}),(!_vm.vertical)?[_c('div',{staticClass:"h-100 position-relative",staticStyle:{"width":"20%","top":"1rem","left":"5.3rem"},attrs:{"id":'real_time_air_quality_chart_4_' + _vm.nanoid}}),_c('div',{staticClass:"h-100 position-relative",staticStyle:{"width":"20%","top":"-1.5rem","left":"2.6rem"},attrs:{"id":'real_time_air_quality_chart_3_' + _vm.nanoid}}),_c('div',{staticClass:"h-100 position-relative",staticStyle:{"width":"20%","top":"0","left":"-3.5rem"},attrs:{"id":'real_time_air_quality_chart_5_' + _vm.nanoid}})]:[_c('div',{staticClass:"h-50",staticStyle:{"width":"20%"}})]],2),(_vm.vertical)?[_c('div',{staticClass:"d-flex position-relative w-100 h-50"},[_c('div',{staticClass:"h-100 position-relative",staticStyle:{"width":"33%"},attrs:{"id":'real_time_air_quality_chart_4_' + _vm.nanoid}}),_c('div',{staticClass:"h-100 position-relative",staticStyle:{"width":"33%"},attrs:{"id":'real_time_air_quality_chart_3_' + _vm.nanoid}}),_c('div',{staticClass:"h-100 position-relative",staticStyle:{"width":"33%"},style:('width:' + (_vm.vertical ? '33%' : '22%')),attrs:{"id":'real_time_air_quality_chart_5_' + _vm.nanoid}})])]:_vm._e()],2),_c('div',{staticClass:"pt-1",staticStyle:{"height":"2rem"}},[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"mr-3"},[_vm._v("室外温度: "),_c('span',{staticClass:"text-success font-digit h4"},[_vm._v(_vm._s(_vm.params.TEMP))]),_vm._v(" °C")]),_c('span',{staticClass:"mr-3"},[_vm._v("室外湿度: "),_c('span',{staticClass:"text-success font-digit h4"},[_vm._v(_vm._s(_vm.params.HUMI))]),_vm._v(" %")]),_c('span',[_vm._v("室外PM2.5: "),_c('span',{staticClass:"text-success font-digit h4"},[_vm._v(_vm._s(_vm.params.PM25))]),_vm._v(" μg/m3")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }